@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap);
body#app {
	margin: 0;
	font-family: "Montserrat", sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.ui {
	font-family: "Montserrat", sans-serif !important;
}

input {
	font-family: "Montserrat", sans-serif !important;
}

h1.ui.header {
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
}
.ui.header {
	font-family: "Montserrat", sans-serif;
	font-weight: 600;
}

.ui.orange.button,
.ui.orange.buttons .button {
	background-color: #eb5b28 !important;
}

